.document-admin-settings{
    .document-approvers-filters, .document-admin-table, .Owner-Change{
        margin-top: 12px;
    }

    .document-admin-table{
        overflow: auto;
        svg {
            margin-left: 4px;
            &.sort {
                &.desc {
                    transform: rotate(180deg);
                    transition: transform 0.5s ease;
                }
                &.asc {
                    transform: rotate(0deg);
                    transition: transform 0.5s ease;
                }
            }
        }
    }

    .buttons{
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
    }

    // .Obsolete-button{
    //     display: inline-block;
    //     margin-left: 80%;
    // }

    .Owner-Change{
        .modify-cycle-modal{
            width: 300px;
            height: 100px;
        }
    }
}