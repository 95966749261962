.table-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow:
        0 20px 36px -8px rgba(14, 14, 44, 0.1),
        0 1px 2px rgba(0, 0, 0, 0.05);
    padding: 12px;
    margin-left: 36px;
    margin-right: 0px;
    width: calc(100% - 36px);
    gap: 12px;
    .filter {
        flex: 1 1 auto;
        min-width: 150px;
        display: flex;
        flex-direction: column;
        &.filter-right-margin {
            margin-right: 12px;
        }
        &.filter-margin {
            margin-top: 20px;
        }
        .input-value {
            height: 38px;
            width: 100%;
        }
        &.action-filter {
            /* position: absolute;
            left: 48px; */
        }
    }
    .filter-dash-margin {
        margin-top: 30px;
    }
    .search-button {
        margin-top: 20px;
        height: 38px;
        &.filter-right-margin {
            margin-right: 12px;
        }
        button {
            height: 38px;
            padding: 10px 3em;
            margin: 0px;
        }
    }
}
