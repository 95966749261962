@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/media-queries';

.edit-attachments {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include respond-below(md) {
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .attachment-label {
        &.mandatory-field {
            &::after {
                margin-left: 0;
            }
        }
    }
    .note {
        font-family: Enphase Visuelt;
        font-size: 11px;
        padding: 4px 0;
        ::first-letter {
            // this is not working
            color: red;
        }
    }

    & > div {
        @include respond-below(xs) {
            flex-direction: column;
            align-items: flex-start;
        }

        align-items: center;
        display: flex;
        margin: 20px 0;
        // padding-right: 160px;
        @include respond-below(md) {
            padding-right: 40px;
        }
        & > label {
            font-size: 12px;
            min-width: 10em;
            order: -1;
            padding: 0 0.5em;
            position: relative;
            white-space: nowrap;
            width: 12em;
        }

        & > label + button {
            min-width: 160px;
            order: 2;
            @include respond-below(xs) {
                margin: 8px 0;
            }
        }

        & > .error-btn {
            background-color: #fff2f2;
            outline: 1px solid $red;
            &:hover {
                color: inherit;
            }
        }
        & .dco-drop-zone {
            display: flex;
            flex: 1 1 100px;
            flex-direction: column;
            .drop-zone {
                background-color: #e5e5e5;
                height: 120px;
                border: 1px dashed $black;
                border-radius: 10px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &.drop-zone-error {
                    outline: 1px solid $red;
                    border: none;
                }
                .content-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    white-space: normal;
                    line-height: 1.5;
                    button {
                        border: none;
                        background-color: #e5e5e5;
                        padding: 0;
                        margin: 0;
                        color: #0b64e9;
                        font-weight: bold;
                        font-size: 14px;
                        &:hover {
                            background-color: #e5e5e5;
                            color: #0b64e9;
                        }
                    }
                }
            }
            .attachment-list {
                display: flex;
                flex-direction: column;
                border: 1px solid #dfe0eb;
                border-radius: 10px;
                margin-top: 10px;
                overflow: hidden;

                .files-header {
                    background-color: #f3f3f3;
                }
                .attachment-item {
                    border-bottom: 1px solid #dfe0eb;
                    padding: 10px 0;
                    color: #0b64e9;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .attachment-name {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-basis: 30%;
                        div {
                            display: flex;
                            cursor: pointer;
                        }
                    }
                    .badge{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 0.2rem;
                        flex-basis: 30%;
                        padding-left: 2rem;
    
                    }
                    .primary-file{
                        color: white;
                        padding: 4px 4px;
                        border-radius: 4px;
                        font-size: 10px;
                        display: inline-block;
                        background-color: #6FB006;
                    }
                    .download {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 1rem;
                        flex-basis: 40%;
                        div {
                            display: flex;
                            cursor: pointer;
                            gap: 0.3rem;
                            svg {
                                background-color: orange;
                                height: 1rem;
                                width: 1rem;
                                border-radius: 50%;
                                padding: 2px;
                                rotate: -45deg;
                            }
                        }
                    }
                    .doc-description {
                        color: $black;
                        flex-basis: 30%;
                        font-size: 12px;
                        letter-spacing: 0.2px;
                        text-align: center;
                    }
                    .uploaded {
                        color: $black;
                        flex-basis: 20%;
                        font-size: 12px;
                        letter-spacing: 0.2px;
                        text-align: center;
                    }
                    .actions {
                        display: flex;
                        gap: 0.3rem;
                    }
                    .file-actions {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 0.4rem;
                        flex-basis: 30%;
                        color: #000;
                        div {
                            display: flex;
                            gap: 0.5rem;
                            svg {
                                background-color: orange;
                                height: 1.2rem;
                                width: 1.2rem;
                                border-radius: 50%;
                                padding: 3px 4px;
                            }
                        }
                        .copy-smartlink svg{
                            all: unset;
                        }
                    }
                    .remove-icon {
                        flex-basis: 10%;
                        display: flex;
                        justify-content: center;
                        color: #000;
                        margin-right:10px;
                        svg:first-of-type {
                            margin-right: 5px;
                        }
                        .delete-svg {
                            transform: rotate(0deg);
                        }
                    }
                    .file-size {
                        color: #808080;
                        font-size: 12px;
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
                &:empty {
                    display: none;
                }
                .add-attachment {
                    display: flex;
                    justify-content: flex-end;
                    padding: 0.3rem;
                    button {
                        padding: 10px 15px;
                    }
                }
                .no-attachments h3{
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    padding-top: 1rem;
                }
            }
        }

        & .attachment-list {
            display: flex;
            flex-direction: column;
            order: 3;
            .attachment-item {
                align-items: center;
                color: #333;
                display: inline-flex;
                h5 {
                    font-weight: normal;
                    padding: 4px;
                }
                svg {
                    cursor: pointer;
                    fill: #333;
                    transform: rotate(45deg);
                }
                .file-actions {
                    svg {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }
}

.add-description {
    background-color: #fff;
    border-radius: 12px;
    outline: none;
    padding: 2rem;
    position: absolute;
    width: 800px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .description-heading {
        display: flex;
        align-items: center;
        .mandatory-field {
            &::after {
                top: 28px;
                margin-left: 0;
            }
        }
        span {
            color: #808080;
        }
    }
    .description-section {
        display: flex;
        margin-top: 20px;
        align-items: center;
        label {
            margin-right: 10px;
            width: 5rem;
        }
        .description-value {
            flex-grow: 1;
        }
        .upload-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                padding: 0.5rem 1.5rem;
            }

            .attachment-list {
                .attachment-item {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                }
            }
            
        }
    }
    .file-attachment {
        .mandatory-field {
            &::after {
                top: 143px;
            }
        }
    }
    .action-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        .submit-button {
            display: inline-flex;
            .submit {
                margin-right: 5px;
            }
        }
    }
    .add-existing {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        button {
            padding: 0.5rem 1.5rem;
        }
    }
}

.delete-attachment {
    background-color: $white;
    border-radius: 12px;
    outline: none;
    padding: 50px;
    position: absolute;
    width: 500px;
    @include align-center;
    @include respond-below(xs) {
        width: 100% !important;
        padding: 1.5rem;
    }
    .heading {
        @include respond-below(xs) {
            align-items: flex-start;
        }
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 16px;

        h1 {
            font-size: 24px;
            text-align: center;
        }
        h4 {
            font-weight: 400;
            line-height: 24px;
        }
    }
    .action-buttons {
        display: flex;
        margin-top: 32px;
        justify-content: space-between;
        button {
            flex-grow: 1;
        }
        .submit-button {
            display: inline;
            .submit {
                margin-right: 5px;
            }
            div {
                display: inline-flex;
                position: absolute;
            }
        }
    }
}
