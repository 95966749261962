@import '../../assets/scss/mixins';

.documentation {
    background: #fff;
    margin: 12px;
    border-radius: 8px;
    box-shadow: 10px 10px 25px rgba(119, 119, 119, 0.5);
    display: block;
    // justify-content: center;
    // flex-direction: column;
    min-height: 80vh;
    padding: 22px;

    strong {
        font-family: 'Enphase Visuelt Bold';
    }

    blockquote {
        background: #f9f9f9;
        border-left: 10px solid $blue;
        margin: 1.5em 0;
        padding: 1em 10px 1em 0px;
        quotes: "\201C""\201D""\2018""\2019";
        letter-spacing: 1px;
    }

    a {
        color: #2079FF;
    }

    div {
        padding: 32px;
        width: 100%;
    }

    h1 {
        // text-align: center;
        font-size: 30px;
        margin-bottom: 1rem;
        color: $enphase-brand-color;
    }

    h2 {
        font-size: 22px;
        margin: 1.5rem 0rem 0rem 0rem;
        color: $enphase-brand-color;
    }

    h3 {
        margin-top: 1rem;
        font-size: 17px;
        color: $enphase-brand-color;
    }

    span {
        float: right;
        border: 1px solid black;
        border-radius: 5px;
        padding: 1rem;
        margin: 1.5rem 0rem 1.5rem 0rem;
    }

    a {
        font-size: 16px;
    }

    ul {
        padding: 0px 0px 0px 40px;
        margin: 0px 0px 16px;
    }

    p {
        font-size: 16px;
        margin: 0.5rem 0rem 0.5rem 1.5rem;
    }

    img {
        display: block;
        margin: 1rem auto 3rem auto;
        width: 95%;
        box-shadow: 10px 10px 25px rgba(119, 119, 119, 0.5);
        border-radius: 0.8rem;
    }
}