@import '../../assets/scss/mixins';

.settings-page {
    @include card;
    display: flex;
    padding: 0;

    .setting-tabs {
        box-shadow: 0 20px 36px rgba(14, 14, 44, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
        height: auto;
        min-height: 320px;
        min-width: 240px;
        padding: 25px 0;
        position: relative;
        width: 240px;

        h1 {
            padding: 0 16px;
        }

        ul {
            list-style: none;
            margin: 16px 0;

            li {
                padding: 12px 16px;

                &:hover {
                    background-color: rgba($blue, 0.2);
                }

                &.active {
                    background-color: rgba($blue, 0.4);
                }
            }
        }
        .admin-heading {
            padding: 16px;
        }
    }

    .setting-main {
        background-color: #faffff;
        border-radius: 0 12px 12px 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        padding: 25px 32px;
        position: relative;

        p {
            color: #757575;
            font-size: 12px;
            line-height: 136.69%;
            padding: 8px 0;
        }
        .flex-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1rem 0;
        }
    }
}
