@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.grid-view{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // padding-top: var(--side-width);
    .doc-type-card {
        background-color: #FFF;
        border-radius: 12px;
        box-shadow: 0 20px 36px -8px rgba(14, 14, 44, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        height: 210px;
        margin: 36px;
        width: 210px;
        .content-section{
            flex-basis: 70%;
            position: relative;
            .content{
                @include align-center;
                align-items: center;
                color: #666;
                display: flex;
                flex-direction: column;
                position: absolute;
                h1{
                    font-family: Enphase Visuelt bold;
                    font-size: 26px;
                    max-width: 190px;
                    overflow: hidden;
                    text-align: center;
                    text-overflow: ellipsis;
                    text-transform: uppercase;
                    white-space: nowrap;
                }

                .status-pill{
                    margin: 6px;
                }

                span {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        .bottom-section{
            background-color: $dark-blue;
            border-radius: 0 0 12px 12px;
            color: $white;
            flex-basis: 30%;
            padding: 12px 20px;
            text-decoration: none;
        }
    }
}

.doc-type-list-view{
    margin: 36px 0;
}