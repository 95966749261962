@import '../../assets/scss/variables';

.doc-detail-timeline{
    .vertical-timeline {
        padding: 0;
        &:last-child::after {
            display: none;    
        }
        .vertical-timeline-element{
       
            margin: 1em 0;
            .vertical-timeline-element-content{
                box-shadow: none;
                margin-left: 16px;
                padding: 0 0 0 1em;
                // adding trailing for each content
                &::before {
                    background: $body-bg;
                    border-radius: 100px;
                    content: '';
                    height: calc(100% - 18px) ;
                    left: -10px;
                    position: absolute;
                    top: 24px;
                    width: 4px;
                }
            }
            .vertical-timeline-element-icon {
                background-color: $green;
                box-shadow: none;
                height: 16px;
                width: 16px;
               
            }
            .Rejected {
                background-color: $red;
            }
            
            p{
                font-size: 12px;
            }

            // to hide tail at end
            &:last-child {
                .vertical-timeline-element-content{
                    &::before {
                        height: 0;
                    }
                }
            }
        }
    }
}