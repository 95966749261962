// .important-fields {
//     > .full{
//         > label{
//             display: none;
//         }
//     }
// }
.document-reference {
    .ref-doc-title {
        background-color: #f3f3f3;
        color: #000;
        padding: 10px;
        border-radius: 8px 8px 0px 0px;
    }
    &.border {
        border: 1px solid #dfe0eb;
        border-radius: 10px;
    }
    &.child {
        width: 80%;
        margin-left: 4%;
    }
    .create-new-document {
        float: right;
        padding: 5px 10px;
    }
    .search-document {
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        .react-select {
            width: 70%;
        }
        &.refPadding {
            padding: 10px;
        }
    }
    .roles-table {
        &.refPadding {
            padding: 10px;
        }
    }
    .document-details {
        background: #eee;

        tbody::before {
            margin-top: 0;
        }
        th,
        td {
            padding: 12px;
            border-radius: 0 !important;
            font-size: 10px;
        }
        td {
            word-wrap: break-word;
            overflow-wrap: anywhere;
        }
        th {
            border-bottom: 1px solid #dfe0eb;
            padding: 12px;
        }
    }
    td,
    th {
        padding: 5px;
        font-size: 12px;
    }

    td {
        .files-column {
            align-items: center;
            display: flex;
            svg {
                margin-left: 4px;
            }
        }
        .action {
            display: flex;
            flex-direction: row;
            span.action-icon {
                align-items: center;
                display: flex;
                // margin-right: 10px;
                width: 25px;
                svg {
                    width: 15px;
                    // &:hover{
                    //     width: 17px;
                    // }
                }
                div {
                    svg {
                        margin-top: 10px;
                    }
                }
            }
        }
        span {
            svg {
                width: 20px;
            }
        }
        button {
            padding: 10px;
        }
    }
}

.create-document__Modal {
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    inset: 15% auto auto 50% !important;
    min-width: 200px;
    padding: 20px;
    position: absolute;
    width: 60%;
    transform: translate(-50%, -50%);
    bottom: auto;
    left: 50%;
    margin-right: '-50%';
}

.select-field {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    .react-select {
        width: 90%;
    }
}
