@import '../../assets/scss/variables';

.toggle{
    display: inline-block;
    height: 20px;
    margin: 0 28px !important;
    position: relative;
    width: 32px;

    &.disabled {
        cursor: not-allowed;
    }
    
    input[type="checkbox"] {
        height: 0;
        visibility: hidden;
        width: 0;
    }
    
    .slider {
        background-color: #7D7D7D;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .4s;

        &::before {
            background-color: #FFF;
            bottom: -2px;
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06);
            content: "";
            height: 27px;
            left: -16px;
            position: absolute;
            transition: .4s;
            width: 27px;
        }
    }

    input:checked + .slider {
        background-color: #61C06A;
    }

    input:disabled + .slider {
        cursor:not-allowed;
        opacity: 0.6;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #61C06A;
    }

    input:checked + .slider::before {
        transform: translateX(36px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round::before {
        border-radius: 50%;
    }
}

