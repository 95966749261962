.contract-table-filters {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow:
        0 20px 36px -8px rgba(14, 14, 44, 0.1),
        0 1px 2px rgba(0, 0, 0, 0.05);
    padding: 12px;
    .filter-row {
        display: flex;
        margin-bottom: 10px;
        .filter {
            width: 200px;
            .input-value {
                height: 38px;
                width: 200px;
            }
            &.filter-right-margin {
                margin-right: 12px;
            }
            &.filter-margin {
                margin-top: 20px;
            }
        }
        .filter-dash-margin {
            margin-top: 30px;
        }
        .add-filter {
            margin-top: 32px;
            margin-right: 12px;
        }
        .delete-filter {
            margin-top: 28px;
            margin-right: 12px;
        }
    }
    .filter-buttons {
        display: flex;
    }
    .filter-summary {
        display: flex;
        align-items: center;
        margin: 0 auto 0 0;
        span {
            margin: 0 5px;
            background-color: #F3F3F3;
            border-radius: 100px;
            font-size: 12px;
            padding: 8px;
            display: flex;
        }
    }
    .filter-arrow {
        margin: 0 auto;
        cursor: pointer;
        svg {
            vertical-align: bottom;
        }
    }
    .button-div {
        margin-top: 20px;
        height: 38px;
        &.filter-right-margin {
            margin-right: 12px;
        }
        button {
            height: 38px;
            padding: 10px 3em;
            margin: 0px;
        }
    }
    .contracts-count {
        margin-top: 20px;
        span {
            font-weight: bold;
        }
    }
}
