.reports-page-container {
    overflow: auto;
    margin-left: 36px;
    margin-right: 36px;
    .report-title {
        margin-bottom: 10px;
    }
}

.save-filter {
    background-color: #fff;
    border-radius: 12px;
    outline: none;
    padding: 12px;
    position: absolute;
    width: 800px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .description-heading {
        display: flex;
        align-items: center;
        span {
            color: #808080;
        }
    }
    .description-section {
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        .mandatory-field {
            &::after {
                top: 55px;
                margin-left: 0;
            }
        }
        label {
            margin-right: 10px;
        }
        .description-value {
            flex-grow: 1;
            margin-top: 5px;
        }
        text-area {
            resize: vertical;
        }
    }
    .action-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        .submit-button {
            display: inline-flex;
            .submit {
                margin-right: 5px;
            }
        }
    }
}