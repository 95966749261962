
@import '../../assets/scss/variables';

.btn {
    display: inline-flex;
    gap:10px;
    justify-content: center;
    button {
        background: #FFF;
        border: 1px solid #0A0908;
        border-radius: 100px;
        cursor: pointer;
        margin: 0 12px;
        padding: 12px 3em;
    
        &:hover{
            background: #3C3C3C;
            color: $white;
        }
    
        &:disabled{
            border: 1px solid #C4C6C4;
            cursor: not-allowed;
        }
      
        &.primary-btn {
            background-color: $black;
            border: none;
            color: $white;
    
            &:hover {
                background: #3C3C3C;
            }
    
            &:disabled{
                background: #C4C6C4;
                cursor: not-allowed;
            }
    
        }
    
        &.link {
            border: none;
            color: $black;
            min-width: 0 !important;
            padding: 0;
    
            &:hover{
                background: none;
                color: #3C3C3C;
            }
    
            &:disabled{
                color: 1px solid #C4C6C4;
                cursor: not-allowed;
            }
        }
    
        &.btn-icon{
            align-items: center;
            display: flex;
            svg{
                margin: 0 4px;
            }
        }
    }
}

button.notify-admin {
    border-radius: 5px;
    padding: 3px 3px;
    margin: 0px 5px;
    background-color: #c0bcbc;
    color: #090909;
    font-size: 15px;
    border: none;

    &:hover {
        background: #d6d5d5;
    }

    &:disabled{
        color: #A9A9A9;
        background: #F0F0F0;
        border: 1px solid #D3D3D3;
        cursor: not-allowed;
    }
}