@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.dashboard-table {
    .files-column {
        align-items: center;
        display: flex;

        svg {
            margin-left: 4px;
        }
    }

    svg {
        margin-left: 4px;
        &.sort {
            &.desc {
                transform: rotate(180deg);
                transition: transform 0.5s ease;
            }
            &.asc {
                transform: rotate(0deg);
                transition: transform 0.5s ease;
            }
        }
    }
}

.doc-number-column {
    .delegated {
        background-color: rgb(231, 222, 136);
        border-radius: 8px;
        font-family: Enphase Visuelt medium;
        font-size: 11px;
        padding: 4px;
        transition: 0.4s ease-out;
        & > .hidden {
            display: none;
        }
        &:hover {
            .hidden {
                display: inline-block;
                transition: 0.4s ease-out;
            }
        }
    }
}

.my-documents-tab,
.my-approvals-tab,
.my-favorite-tab,
.my-latest-tab,
.my-reviews-tab {
    display: flex;
    .table-section {
        flex: 1;
        overflow: auto;
        table tbody tr td {
            @include respond-above(md) {
                padding: 15px 12px;
            }
        }
    }
    .confidential {
        color: $red;
    }
}
