@import '../../../assets/scss/mixins';
@import '../../../assets/scss/media-queries';

.trip-details-container {
    @include tabs;
    .tab {
        margin-bottom: 1rem;
        overflow: auto;

        .tab-icons {
            width: 16px;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        button {
            line-height: 10px;
        }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .tab::-webkit-scrollbar {
        display: none;
    }


    span.action-icon {
        align-items: center;
        display: flex;
        width: 25px;
        svg {
            width: 15px;
        }
        div {
            svg {
                margin-top: 10px;
            }
        }
    }

    .table-wrapper {
        border-collapse: collapse;
        border: 1px solid #ccc;
        border-radius: 8px;
    }

    .styled-table {
        width: 100%;
    }

    .styled-table tr:last-child td {
        border-bottom: 0px;
    }

    .styled-table th,
    .styled-table td {
        padding: 0.5rem;
        text-align: left;
        border-bottom: 1px solid #ccc;
    }

    .styled-table th:first-child {
        border-radius: 0px;
        border-top-left-radius: 8px;
    }

    .styled-table th:last-child {
        border-radius: 0px;
        border-top-right-radius: 8px;
    }

    .styled-table th {
        background-color: #f0f0f0;
    }

    .styled-input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .styled-date-picker {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-family: Enphase Visuelt;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 20px;
    }

    .styled-button {
        display: block;
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        background-color: #fff;
        color: #f37321;
        border-color: #f37321;
        border-radius: 4px;
        cursor: pointer;
    }

    .styled-button:hover {
        color: #fff;
        background-color: #f37321;
    }
}
