@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.footer{
    @include card;
    align-items: center;
    border-radius: 0;
    display: flex;
    justify-content: center;
    margin: var(--side-width) 0 0 0;
}