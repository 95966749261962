@import '../../assets/scss/mixins';
@import '../../assets/scss/media-queries';

.search {
    margin: 0 var(--side-width);
    @include tabs;

    .section {
        display: flex;
        .filters-section {
            @include card;
            margin: 0;
        }

        .filters-section {
            margin-right: 20px;
            min-width: 260px;
            width: 260px;

            @include respond-below('sm') {
                display: none;
            }
        }

        .doc-list {
            flex-grow: 1;
            .search-wrapper {
                @include card;
                margin: 12px 0;
            }
            .search-pagination-merge {
                @include card;
                align-items: center;
                display: flex;
                margin: 0;
                padding: 0;
                .searched-params {
                    border-radius: 12px 0 0 12px;
                    flex: 1;
                    margin: 0;
                }
            }
        }
    }
}
