.react-datepicker{
    width: 100%;
    input {
        width: 100%;
    }
}
.custom-date-input {
    display: inline-block;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .custom-date-input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-date-input::-webkit-calendar-picker-indicator {
    background-color: transparent;
    padding: 4px;
    cursor: pointer;
  }