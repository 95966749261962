@import '../../assets/scss/variables';

.filters{
    .heading{
        
        align-items: center;
        display: flex;
        justify-content: flex-end;
        div{
            margin-right: auto;
            span {
                color: #5F6062;
                font-size: 12px;
                letter-spacing: 0.02em;
                // text-transform: capitalize;
            }
        }
        button{
            font-size: 12px;
            margin: 0;
            padding: 8px 12px;
        }
    }
    .filter-list{
        & > div{
            margin: 24px 0;
            label{
                color: #5F6062;
                font-size: 11px;
                font-style: normal;
                font-weight: bold;
                line-height: 12px;
            }

            & label + * {
                // border-radius: 100px;
                margin-top: 4px;
            }
            input.date-picker {
                border: 1px solid $dark-blue;
                font-size: 12px;
                width: 100%;
            }
    
            .react-select{
                .select__control{
                    border: 1px solid $dark-blue;
                    // border-radius: 100px;
                }
            }
        }
        .primary-btn {
            font-weight: 550;
            margin: 0;
            width: 100%;
        }
        &__loading{
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
    input {
        border: 1px solid #2D3047;
        width: 100%;
    }
    .double-date-filter{
        .dates {
            border: 1px solid #000;
            border-radius: 8px;
            display: flex;
            input {
                border: none;
                width: 100%;
                &:focus-visible, &:focus {
                    outline: none;
                }
                &:first-child {
                    padding-right: 0;
                }
                &:last-child {
                    padding-left: 4px;
                }
            }
            .divider{
                background-color: #2D3047;
                width: 2px;
            }
        }
        .left-arrow, .right-arrow{
            margin: 0 12px;
            &.disabled {
                cursor: not-allowed;
            }
        }
        .left-arrow{
            transform: rotate(180deg);
        }
    }

    .filter-user-search{
        .select__indicator{
            display: none;
        }
    }
}