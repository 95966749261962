@import '../../assets/scss/mixins';

@include tabs;

.reports-detail-summary-page {
    padding: 10px 32px;
    .report-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .status-pill {
            margin-left: 10px;
            height: 26px;
        }
        div {
            display: flex;
        }
    }
    .tab {
        // ! REVIEWER-FIX
        overflow: auto;
        .tab-icons {
            width: 16px;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        margin-left: 0;
        margin-bottom: 10px;
    }
    svg {
        margin-left: 4px;
        &.sort {
            &.desc {
                transform: rotate(180deg);
                transition: transform 0.5s ease;
            }
            &.asc {
                transform: rotate(0deg);
                transition: transform 0.5s ease;
            }
        }
    }
}
