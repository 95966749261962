@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.input-textbox {
    border-radius: 8px;
    box-sizing: border-box;
    font-family: $font-family;
    font-size: 12px;
    padding: 12px;
    resize: vertical;
    width: 100%;
    @include field-border;
    &:disabled {
        background-color: #F3F3F3;
        color: #000;
        cursor:not-allowed;
        font-weight: 500;
    }
}