.inactive-approvers-filters{
    align-items: center;
    display: flex;
    gap: 18px;
    .select-inactive-approver, .select-user{
        flex-basis: 50%;
        .select__dropdown-indicator{
            display: none;
        }
    }
}