@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.document-type-create{
    margin: 0 var(--side-width);
    @include tabs {
        margin-top: 20px;
        button {
            align-items: center;
            display: inline-flex;
            &.active{
                svg{
                    background: #FFF;
                    border-radius: 100px;
                    outline: 1px solid;
                }
            }

            svg{
                margin-left: 12px;
            }
        }
    };
}