.admin-doc-type-table {
    overflow: auto;
    margin-left: 12px;
    margin-right: 36px;
    flex-direction: column;
    table thead {
        th:first-child {
            width: 250px;
        }
        th:nth-child(5),
        th:nth-child(6),
        th:nth-child(7),
        th:nth-child(8),
        th:nth-child(10),
        th:nth-child(11),
        th:nth-child(13) {
            width: 100px;
        }
        th:nth-child(14) {
            width: 120px;
        }
    }
    svg {
        margin-left: 10px;
        &.sort {
            &.desc {
                transform: rotate(180deg);
                transition: transform 0.5s ease;
            }
            &.asc {
                transform: rotate(0deg);
                transition: transform 0.5s ease;
            }
        }
    }

    .cell-value-margin {
        margin-left: 27%;
    }
    .filter-search-input {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 8px;
        .search-icon {
            position: absolute;
            top: 12px;
            right: 12px;
        }
        input {
            margin-left: 10px;
            font-family: "Enphase Visuelt medium";
            font-size: 14px;
            padding: 12px;
            text-align: left;
            width: 300px;
        }
    }
    .document-type {
        .tool-tip {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            visibility: hidden;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            margin-left: 60px;
            margin-top: -50px;
            cursor: pointer;
            .block-margin {
                margin: 10px;
                .tool-tip-data {
                    color: #00ffff;
                }
            }
        }
    }
    .document-type:hover .tool-tip {
        visibility: visible;
    }
    .react-select {
        margin-top: 5px;
    }
}
.admin-doc-type-table.admin-dashboard-main {
    margin-left: 100px;
}
.inactive-approvers-admin-setting.admin-dashboard-main {
    margin-right: 0px;
    margin-left: 100px;
}
