@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/media-queries';

.AddToChange-Modal {
    background-color: #f3f3f3;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    inset: 5% auto auto 20% !important;
    min-width: 200px;
    padding: 25px;
    position: absolute;
    width: 60%;

    .document-approvers-filters{
        margin-top: 2px;
        align-items: center;
        display: flex;
        gap: 18px;

        .input-search {
            max-width: 120px;
            height: 30px;
        }
    }

    .Create-NewChange{
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .table-section{
        max-height: 65vh;
        overflow: auto;
        position: relative;
        margin-top: 10px;
        .table-in-modal{
    
            .thead-in-modal{
                top: 0;
                position: sticky;
            }
    
            .tbody-in-modal{
    
                .rowheight-modal{
                    padding: 8px;

                    .align-radio-in-modal{
                        display: flex;
                        align-items: center;

                        .DCO-modal{
                            margin-left: 4px;
                            margin-top: 3px;
                        }
                    }
                }
            }
        }

        .submit-button{
            margin-top: 10px;
        }
    }
}

.no-draft-dcos-found {
    @include card;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    margin-bottom: 0;
    padding: 20px 10px;
    h1{
        font-family: 'Bebas Neue', cursive;
        font-size: 24px;
    }
    svg{
        height: 50%;
        width: 50%;
    }
}

.add-to-change-btns {
    border-color: #f37321;
    color: #f37321;
}

.add-to-change-btns:hover {
    background: #f37321;
}