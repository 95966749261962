.document-history {
    .document-phase {
        &.release {
            background-color: #6c6c6c;
        }
        &.rerelease {
            background-color: #1d4289;
        }
        &.obsolete {
            background-color: #de2100;
        }
    }
}