.viewdiffTable {
    margin-top: 15px;
}

.AddToChange-Modal td {
    white-space: pre-wrap;
}

.modal-legend {
    display: flex;
    margin-top: 14px;
    align-items: center;
    .newly-added-icon {
        display: flex;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #90ee9052;
        margin-right: 2px;
    }
    .newly-added {
        margin-right: 20px;
        margin-top: 4px;
    }
    .Modified-icon {
        display: flex;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: lightcoral;
        margin-right: 2px;
    }
    .Modified {
        margin-right: 20px;
        margin-top: 4px;
    }
    .No-Change-icon {
        display: flex;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: white;
        margin-right: 2px;
    }
    .No-Change {
        margin-top: 4px;
    }
}

/* Conditional row styles */
.row-empty-to-value {
    background-color: #90ee9052;
}

.row-modified-value {
    background-color: lightcoral;
}

table.viewdiffTable thead th {
    min-width: 40%;
}

table.viewdiffTable thead th:first-child {
    min-width: 14%;
}
