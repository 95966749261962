.document-admin-table {
    overflow: auto;
    margin-left: 36px;
    width: calc(100% - 36px);
    svg {
        margin-left: 4px;
        &.sort {
            &.desc {
                transform: rotate(180deg);
                transition: transform 0.5s ease;
            }
            &.asc {
                transform: rotate(0deg);
                transition: transform 0.5s ease;
            }
        }
    }
}
