.inactive-approvers-admin-setting {
    .inactive-approvers-filters,
    .inactive-approvers-actions,
    .inactive-approvers-admin-table {
        margin-top: 12px;
    }

    .inactive-approvers-admin-table {
        overflow: auto;
    }
}
.inactive-approvers-admin-setting.admin-dashboard-main {
    width: 100%;
}
