@import '../../assets/scss/variables';

.user-search__modal {
    background-color: $white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    inset: 15% auto auto 50% !important;
    min-width: 200px;
    padding: 12px;
    position: absolute;
    width: 60%;

    & > .note {
        font-family: Enphase Visuelt;
        font-size: 11px;
        padding: 4px 0;
    }

    .react-select.user-search {
        margin-top: 12px;
    }
    .button-grp {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        button {
            &:last-child {
                margin-right: 0;
            }
            font-size: 14px;
            font-weight: 550;
        }
    }
}
