@import './variables';
@import 'media-queries';

@mixin card {
    background-color: $primary-color;
    border-radius: 12px;
    box-shadow: 0 20px 36px -8px rgba(14, 14, 44, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
    margin: 0 var(--side-width);
    padding: 25px 32px;
    @content;
}

@mixin field-border {
    border: 1px solid #DFE0EB;
}

@mixin tabs($radius: 8px) {
    .tab{
        background-color: $white;
        border-radius: 100px;
        box-shadow: 0 20px 36px rgba(14, 14, 44, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
        display: inline-flex;
        @content;

        @include respond-below('sm') {
            width: 100%;
        }

        button {
            align-items: center;
            background-color: $white;
            border: none;
            border-radius: 100px;
            color: #7D7D7D;
            display: inline-flex;
            flex-grow: 1;
            font-size: 11px;
            font-weight: 550;
            gap: 4px;
            justify-content: center;
            line-height: 20px;
            margin: 4px;
            text-transform: uppercase;
            transition: .4s ease-out;
            
            &:hover{
                background:inherit;
                color: inherit;
            }

            &.active {
                background-color: #F37321;
                color: $white;
                transition: .4s ease-out;
            }
            @include respond-below('sm') {
                .name {
                    display: none;
                }
            }
        }
    }
}

@mixin align-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin btn-with-icon {
    button {
        align-items: center;
        border: none;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        font-size: 14px;
        margin: 0;
        padding: 12px;

        span {
            margin-right: 12px;
        }
    }
}