@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../../assets/scss/media-queries';

.republish-modal {
    h4 {
        padding-bottom: 1.5rem;
    }
    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        label {
            min-width: 180px;
        }
        .react-datepicker {
            border: none;
            input {
                width: 150px;
                background: url('../../assets/images/calendar.svg') no-repeat 110px 10px;
                background-size: 20px;
            }
        }
        input {
            width: 150px;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }
    }
    button {
        align-self: self-end;
        margin-right: 2rem;
    }
}
