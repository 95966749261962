@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../../assets/scss/media-queries';

.confirmation__modal {
    background-color: $white;
    border-radius: 12px;
    outline: none;
    padding: 50px;
    position: absolute;
    width: 500px;
    @include align-center;
    @include respond-below(xs) {
        width: 100% !important;
        padding: 1.5rem;
    }
    .heading {
        @include respond-below(xs) {
            align-items: flex-start;
        }
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 16px;

        h1 {
            font-size: 24px;
            text-align: center;
        }
        h4 {
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            width: 90%;
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        button {
            flex-grow: 1;
        }
    }
    @include respond-below(xs) {
        .close-btn {
            display: none;
        }
    }
}
