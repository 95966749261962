/* Container for the entire admin dashboard and contracts dashboard */
.admin-dashboard,
.contracts-dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    .sidebar {
        min-width: 64px;
        width: 64px;
        position: absolute;
        /* height: calc(100% + 33px); */
        padding: 1rem 0;
        left: 0;
        background-color: #fff;
        box-shadow: 20px 20px 25px rgba(0, 0, 0, 0.1);
        border-right: 0.25px solid #d9d9d9;
        /* border-radius: 0 5px 5px 0; */
        z-index: 2;
        transition: 0.3s linear;
        .navbar-brand {
            padding: 14px 16px;
            display: flex;
            color: #777777;
            align-items: center;
            text-decoration: none;
            span {
                font-size: 16px;
            }
            .route-name {
                display: none;
            }
        }
        &:hover {
            transition: 0.2s ease-out;
            min-width: 12rem;
            width: 12rem;
            .navbar-brand {
                .route-name {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    transition: all 0.3s;
                    padding-left: 8px;
                }
            }
        }
    }
}
.admin-dashboard .admin-dashboard-main {
    margin-left: 64px;
    margin-right: 36px;
    display: flex;
    flex-wrap: wrap;
}
.status-panel {
    flex-basis: 100%; /* Make this panel take up the entire width */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0px 5px;
}
/* Style for individual status elements */
.statusEach {
    flex-basis: calc(
        20% - 20px
    ); /* Each status element takes 50% width minus margin on both sides (two side by side) */
    border: 1px solid #ddd;
    padding: 20px; /* Adjust padding for inner spacing */
    margin: 10px; /* Adjust margin for outer spacing */
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.statusEach:first-child {
    margin-left: 0px;
}
.statusEach:last-child {
    margin-right: 0px;
}

/* Style for individual status element headings */
.statusEach h3 {
    font-size: 16px;
    margin: 5px;
}

/* Style for individual admin panels */
.admin-panel {
    flex-basis: calc(50% - 10px); /* Each panel takes 50% width*/
    border: 1px solid #ddd;
    padding: 20px; /* Adjust padding for inner spacing */
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0px 5px;
}

/* Style for admin panel headings */
.admin-panel h2 {
    font-size: 18px;
}
.admin-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin-panel-header button {
    margin-left: auto;
    padding: 5px 10px;
}

.viewMore {
    margin: 20px 12px;
}
