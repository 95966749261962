.contracts-page-table {
    svg {
        margin-left: 4px;
        &.sort {
            &.desc {
                transform: rotate(180deg);
                transition: transform 0.5s ease;
            }
            &.asc {
                transform: rotate(0deg);
                transition: transform 0.5s ease;
            }
        }
    }
}