// $font-family-sans-serif:
$body-bg: #E5E5E5;
$font-family: 'Roboto', sans-serif;
$primary-color: #FFF;
$green: rgb(111, 176, 6);
$red: rgb(216, 3, 17);
$blue: #2079FF;
$orange: rgba(233, 140, 0, 1);
$white: #FFF;
$black: #000;
$enphase-brand-color: #F37321;
$disabled-grey: rgba(243, 243, 243, 1);
$dark-blue: #2D3047;

$state-red: #D80311;
$state-yellow: #E98C00;
$state-green: #6FB006;

$side-width: 36px;


// #dfe0eb -> rgba(223, 224, 235, 1)