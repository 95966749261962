@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../../assets/scss/media-queries';

.doc-create__modal {
    background-color: $body-bg;
    border-radius: 12px;
    display: flex;
    outline: none;
    padding: 36px;
    position: absolute;
    top: 100px !important;
    transform: translate(-50%, 0) !important;
    width: 85%;
    @include align-center;

    .doc-type-list {
        width: 100%;
        &__header {
            @include respond-below(xs) {
                flex-direction: column;
            }
            display: flex;
            justify-content: space-between;
            margin: 12px;

            .doc-type__info {
                align-items: center;
                display: inline-flex;

                .create-doc-heading {
                    font-size: 14px;
                }

                svg {
                    height: 42px;
                    margin-right: 12px;
                    width: 42px;
                }

                p {
                    font-size: 14px;
                }
            }

            .doc-type-search {
                input {
                    @include respond-below(xs) {
                        margin: 8px 0;
                        width: 100%;
                    }
                    border: 1px solid #ced4da;
                    border-radius: 100px;
                }
            }
        }

        &__content {
            display: flex;
            margin-top: 16px;

            .list {
                flex: 1 1 50%;
                .doc-type-filters {
                    padding: 16px;
                    display: flex;
                    justify-content: space-between;
                    gap: 2rem;
                    position: relative;

                    .doc-type-search {
                        display: flex;
                        gap: 0.7rem;
                        .filter-dco-docs {
                            display: flex;
                            align-items: center;
    
                            .filter-icon {
                                cursor: pointer;
                            }
                            
                            .filter-btn-modal {
                                @include card;
                                align-items: flex-start;
                                border-radius: 0 0 8px 8px;
                                box-shadow: 0 20px 36px rgba(14, 14, 44, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
                                display: flex;
                                flex-direction: column;
                                gap: 0.5rem;
                                margin: 0;
                                min-width: 13rem;
                                padding: 10px 5px;
                                position: absolute;
                                top: 60px;
                                z-index: 2;
                        
                                @include respond-below ('sm') {
                                    min-width: auto;
                                    top: 80px;
                                }
            
                                button {
                                    font-size: 0.7rem;
                                    font-weight: 500;
                                    min-width: 90%;
                                    height: 2rem;
                                }
                            }
                        }
                    }
                    .doc-type-sort {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1rem;
                        label {
                            font-size: 14px;
                            white-space: nowrap;
                        }
                        .react-select {
                            min-width: 9rem;
                        }
                    }
                    .search-info {
                        position: relative;
                        background-color: hsl(0, 0%, 100%);
                    }
                }

                ul {
                    list-style: none;
                    list-style-type: none;
                    max-height: calc(100vh - 350px);
                    overflow-y: auto;

                    li {
                        align-items: center;
                        background-color: $white;
                        border-radius: 12px;
                        cursor: pointer;
                        display: flex;
                        justify-content: flex-end;
                        margin: 12px;
                        padding: 16px;

                        h4 {
                            margin-right: auto;
                        }

                        P {
                            color: #5f6062;
                            font-size: 12px;
                        }

                        &.active {
                            background: rgba($blue, 0.2);
                        }
                    }
                }

                .no-docs-found {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 12rem;
                }
            }
        }

        .doc-type-detail {
            @include card;
            flex: 1 1 50%;
            margin: 12px;
            padding: 16px;
            position: relative;

            .doc-type-detail__header {
                align-items: center;
                display: flex;
                justify-content: space-between;

                button {
                    margin: 0;
                }
            }

            .department {
                align-items: center;
                display: flex;
                justify-content: center;
                margin-top: 10px;
                gap: 10px;
                .react-select{
                    width: 100%;
                }
            }

            .category {
                align-items: center;
                display: flex;
                justify-content: center;
                margin-top: 10px;
                gap: 10px;
                .react-select{
                    width: 100%;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                max-height: calc(100vh - 350px);
                overflow-y: auto;
                overflow-x: hidden;

                p {
                    margin-top: 22px;
                }
            }

            .select-doc-type {
                position: absolute;
                @include align-center;
            }
        }

        & > .back-label {
            align-items: self-end;
            display: flex;

            & > .back-icon {
                transform: rotate(180deg);
            }
        }
    }
    @include respond-below(xs) {
        .hidden {
            display: none;
        }
    }
}
