@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/media-queries';

.add_role__modal {
    align-items: center;
    background-color: $white;
    border-radius: 12px;
    outline: none;
    padding: 24px 16px 24px 32px;
    position: absolute;
    width: 500px;
    @include align-center;

    .modal-body {
        .heading {
            h1 {
                padding-bottom: 16px;
            }
        }
        .hrbp-input {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            .entity-input{
                width: 100%;
            }
            label {
            }
            input {
                width: 50%;
                background: #fff;
                border: 1px solid #dfe0eb;
                border-radius: 8px;
                box-sizing: border-box;
                padding: 12px 16px;
            }
            textarea {
                border-radius: 8px;
                box-sizing: border-box;
                font-family: $font-family;
                // font-size: 12px;
                padding: 12px;
                resize: vertical;
                width: 100%;
                @include field-border;
                &:disabled {
                    background-color: #f3f3f3;
                    color: #000;
                    cursor: not-allowed;
                    font-weight: 500;
                }
            }
        }
        .button-group {
            margin-top: 32px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
