@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/media-queries';

.files-list {
    display: flex;
    flex: 1 1 80%;
    padding: 0 10px;

    align-items: center;
    margin: 20px 0;
    // padding-right: 160px;
    // @include respond-below(md) {
    //     padding-right: 40px;
    // }

    &>label {
        font-size: 12px;
        order: -1;
        padding: 0 0.5em;
        position: relative;
        width: 11em;

        &.mandatory-field {
            &::after {
                color: $red;
                content: '*';
                margin-left: 2px;
                position: relative;
                right: auto;
                top: 0px;
            }
        }
    }

    &>label+* {
        flex: 1 1 100px;
        order: 2;
    }

    &.full {
        flex: 1 1 80%;
        padding: 0 10px;
    }

    &.half {
        flex: 1 1 40%;
        padding: 0 10px;
        // &:nth-child(3) {
        //     margin-left: 36px;
        // }
    }

    .right-section {
        display: flex;
        flex: 1 1 100px;
        flex-direction: column;
        margin-top: 10px;

        .attachment-list {
            display: flex;
            flex-direction: column;
            border: 1px solid #dfe0eb;
            border-radius: 10px;
            margin-top: 10px;
            overflow: hidden;
            .files-header {
                background-color: #f3f3f3;
            }
            .attachment-item {
                border-bottom: 1px solid #dfe0eb;
                padding: 10px 0;
                color: #0b64e9;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .attachment-name {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    flex-basis: 30%;
                    div {
                        display: flex;
                        cursor: pointer;
                        gap: 0.4rem;
                        svg {
                            background-color: orange;
                            height: 1rem;
                            width: 1rem;
                            border-radius: 50%;
                            padding: 2px;
                        }
                    }
                }
                .badge{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0.2rem;
                    flex-basis: 30%;
                    padding-left: 2rem;

                }
                .primary-file{
                    color: white;
                    padding: 4px 4px;
                    border-radius: 4px;
                    font-size: 10px;
                    display: inline-block;
                    background-color: #6FB006;
                }
                .download {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    flex-basis: 40%;
                }
                .doc-description {
                    color: $black;
                    flex-basis: 30%;
                    font-size: 12px;
                    letter-spacing: 0.2px;
                    text-align: center;
                }
                .uploaded {
                    color: $black;
                    flex-basis: 20%;
                    font-size: 12px;
                    letter-spacing: 0.2px;
                }
                .actions {
                    display: flex;
                    gap: 0.3rem;
                }
                .file-actions {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.4rem;
                    flex-basis: 30%;
                    color: #000;
                    div {
                        display: flex;
                        gap: 0.5rem;
                        svg {
                            background-color: orange;
                            height: 1.2rem;
                            width: 1.2rem;
                            border-radius: 50%;
                            padding: 3px 4px;
                        }
                    }
                    .copy-smartlink svg{
                        all: unset;
                    }
                }
                .remove-icon {
                    flex-basis: 10%;
                    display: flex;
                    justify-content: flex-end;
                    color: #000;
                    svg:first-of-type {
                        margin-right: 5px;
                    }
                }
                .file-size {
                    color: #808080;
                    font-size: 12px;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
            &:empty {
                display: none;
            }
            .add-attachment {
                display: flex;
                justify-content: flex-end;
                padding: 0.3rem;
                button {
                    padding: 10px 15px;
                }
            }
            .no-attachments h3{
                text-align: center;
                text-transform: uppercase;
                font-size: 1.5rem;
                padding-top: 1rem;
            }
        }
    }
}