.delegate-goals {
    .delegation-widget {
        .from-row {
            display: flex;
            .form-group {
                margin: 12px;
                width: 100%;
                .react-select-delegate,
                .react-datepicker__input-container input {
                    width: 100%;
                }
            }
        }
        .buttons {
            display: flex;
            justify-content: flex-end;
        }
    }
    .delegation-history {
        margin : 24px 12px
    }
}
