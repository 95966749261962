@import '../../../assets/scss/variables';

.inactive-approvers-actions{
    display: flex;
    justify-content: center;
    .select-approver{
        align-items: center;
        display: flex;
        justify-content: flex-start;
        &__label {
            margin-right: 12px;
            white-space: nowrap;
        }
        &__select {
            min-width: 200px;
            .select__dropdown-indicator{
                display: none;
            }
        }
    }
}

.inactive-approvers-buttons-group{
    margin-left: auto;
    .btn--blue{
        border: 1px solid #1D4289;
        color: #1D4289;
        svg{
            stroke: #1D4289;
        }
        &:hover{
            background-color: rgba(#1D4289, 0.7) ;
            color: #FFF;
            svg{
                stroke: #FFF;;
            }
        }
      
    }
    .btn--red{
        border: 1px solid $red;
        color: $red;
        svg{
            stroke: $red;
        }
        &:hover{
            background-color: rgba($red, 0.7) ;
            color: #FFF;
            svg{
                stroke: #FFF;
            }
        }
       
    }
    button:last-child {
        margin-right: 0;
    }
    button:disabled {
        border: 1px solid #C4C6C4;
        color: #C4C6C4;
        &:hover{
            background-color: inherit;
            color: #C4C6C4;
        }
    }

    &.small-buttons{
        display: inline-flex;
        flex-wrap: wrap;
        gap: 10px;
        .btn            {
            margin: 0;
            padding: 6px 1.5em;
        }
    }
}