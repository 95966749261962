@import '../../assets/scss/variables';
@import '../../assets/scss/media-queries';

.important-fields {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .update-button {
        float: right;
        cursor: pointer;
        margin: 5px 1px;
    }

    @include respond-below(md) {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    & > div {
        align-items: center;
        display: flex;
        margin: 20px 0;
        // padding-right: 160px;
        // @include respond-below(md) {
        //     padding-right: 40px;
        // }

        & > label {
            font-size: 12px;
            order: -1;
            padding: 0 0.5em;
            position: relative;
            width: 11em;
            &.mandatory-field {
                &::after {
                    color: $red;
                    content: '*';
                    margin-left: 2px;
                    position: relative;
                    right: auto;
                    top: 0px;
                }
            }
        }

        & > label + * {
            flex: 1 1 100px;
            order: 2;
        }

        &.full {
            flex: 1 1 80%;
            padding: 0 10px;
        }

        &.half {
            flex: 1 1 40%;
            padding: 0 10px;
            // &:nth-child(3) {
            //     margin-left: 36px;
            // }
        }
    }

    // input{
    //     width: 100%;
    // }
}
