.reports-page-table {
    svg {
        margin-left: 4px;
        &.sort {
            &.desc {
                transform: rotate(180deg);
                transition: transform 0.5s ease;
            }
            &.asc {
                transform: rotate(0deg);
                transition: transform 0.5s ease;
            }
        }
    }
    .table-section {
        overflow-x: auto;
        table {
            thead th:last-child, thead th:nth-child(7), thead th:nth-child(10),
            tbody td:last-child, tbody td:nth-child(7),  tbody td:nth-child(10){
                text-align: center;
            }
        }
    }
}