@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.markup-field {
    box-sizing: border-box;
    font-family: Enphase Visuelt;
    padding: 5px;
    resize: vertical;
    width: 100%;
    border-bottom: 1px lightgray solid;
}