@import '../../assets/scss/mixins';
@import '../../assets/scss/media-queries';

.table-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .table-1, .table-2 {
        table-layout: fixed;
        width: 90%;
        border-spacing: 0;
        overflow-x: auto;
        margin: 0 auto;

        .table-title {
            padding: 10px;
            padding-left: 0;
            font-size: large;
        }
        .thead th {
            background: white;
        }

        th, td {
            padding: 10px;
            text-align: left;
            vertical-align: top;
        }
    }

}