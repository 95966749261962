.text-box-with-chip {
    font-size: 13px;
    .rti--container{
        border-radius: 8px;
        min-height: 36px;

    }
    .rti--tag {
        span {
            font-size: 12px;
            margin: 2px 8px;
        }
        button {
            margin: 0;
        }
    }
   
    &.disabled {
        cursor: not-allowed;
        .rti--container{
            background-color: #F3F3F3;
        }
        .rti--tag {
            background-color: #CFCFCF;
            cursor: not-allowed;

            span {
                color: #000;
                font-weight: 500;
                margin: 2px 12px;
            }
            button {
                cursor: not-allowed;
                display: none;
            }
        }
        input {
            display: none;
        }
    }
}
