@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.reports-list-page-table {
    svg {
        margin-left: 4px;
        &.sort {
            &.desc {
                transform: rotate(180deg);
                transition: transform 0.5s ease;
            }
            &.asc {
                transform: rotate(0deg);
                transition: transform 0.5s ease;
            }
        }
    }
    .table-section {
        overflow-x: auto;
        table {
            thead th:last-child,
            tbody td:last-child {
                text-align: center;
            }
        }
    }
}

.delete-attachment {
    background-color: $white;
    border-radius: 12px;
    outline: none;
    padding: 50px;
    position: absolute;
    width: 500px;
    @include align-center;
    @include respond-below(xs) {
        width: 100% !important;
        padding: 1.5rem;
    }
    .heading {
        @include respond-below(xs) {
            align-items: flex-start;
        }
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 16px;

        h1 {
            font-size: 24px;
            text-align: center;
        }
        h4 {
            font-weight: 400;
            line-height: 24px;
        }
    }
    .action-buttons {
        display: flex;
        margin-top: 32px;
        justify-content: space-between;
        button {
            flex-grow: 1;
        }
        .submit-button {
            display: inline;
            .submit {
                margin-right: 5px;
            }
            div {
                display: inline-flex;
                position: absolute;
            }
        }
    }
}