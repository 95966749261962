.status-pill.active {
    background-color: #6cc36c; /* Green background for active state */
    border-color: #59db2adc; /* Green border color for active state */
}

/* Styling for inactive status-pill */
.status-pill.inactive {
    background-color: #d85a5a; /* Red background for inactive state */
    border-color: #d85a5a; /* Red border color for inactive state */
}
