@import '../../assets/scss/mixins';
@import '../../assets/scss/media-queries';

@include tabs;
.reports-tab {
    // ! REVIEWER-FIX
    overflow: auto;
    .tab-icons {
        width: 16px;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    margin-left: 30px;
}
.reports {
    margin-left: 36px;
    margin-right: 36px;
    margin-top: 10px;
    .create-new-report {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
}

.create-custom-report {
    background-color: #e5e5e5;
    border-radius: 12px;
    outline: none;
    padding: 12px;
    position: absolute;
    width: 1200px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    height: 90%;
    .description-heading {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        span {
            color: #808080;
        }
    }
    .report-fields {
        background-color: #fff;
        border-radius: 12px;
        outline: none;
        padding: 12px;
        .description-fields {
            display: flex;
            flex-direction: row;
            .description-section {
                display: flex;
                margin-top: 20px;
                width: 40%;
                flex-direction: column;
                margin-right: 10%;
                .mandatory-field {
                    &::after {
                        top: 75px;
                        margin-left: 0;
                    }
                }
                label {
                    margin-right: 10px;
                }
                .description-value {
                    margin-top: 5px;
                }
                text-area {
                    resize: vertical;
                }
            }
        }
    }
    .table-section {
        height: 500px;
        margin-top: 10px;
        .no-documents-found {
            padding: 200px 100px;
        }
    }
    .action-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        .submit-button {
            display: inline-flex;
            .submit {
                margin-right: 5px;
            }
        }
    }
}