@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../../assets/scss/media-queries';

.checkout__confirmation__modal {
    background-color: $white;
    border-radius: 12px;
    outline: none;
    padding: 50px;
    position: absolute;
    width: 500px;
    align-items: center;
    @include align-center;
    @include respond-below(xs) {
        width: 100% !important;
        padding: 1.5rem;
    }

    .heading {
        @include respond-below(xs) {
            align-items: flex-start;
        }
        align-items: center;
        flex-direction: column;
        margin: 16px;

        h1 {
            font-size: 24px;
            text-align: center;
        }
        .check-out-info {
            gap: 5px;
            font-size: 14px;
            color: #856404;
            background-color: #fff3cd;
            border-color: #ffeeba;
            padding: 0.25rem;
            margin-top: 10px;
            margin-bottom: 1px;
            border: solid transparent;
            border-radius: 0.35rem;

            .warning {
                .warn-icon {
                    display: inline;
                    height: 20px;
                }
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        button {
            flex-grow: 1;
        }
    }
    @include respond-below(xs) {
        .close-btn {
            display: none;
        }
    }
}
